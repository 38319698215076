<script>
import { defineComponent } from "vue";
import OmniSelect from "@/components/controls/OmniSelect.vue";
import OrderStopDateTime from "@/views/orders/components/edit/OrderStopDateTime.vue";
import ValidationMixin from "@/mixins/validation.mixin";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import OrdersMixin from "@/views/orders/OrdersMixin.vue";

export default defineComponent({
  name: "StopDateRange",
  components: { OrderStopDateTime, OmniSelect },
  mixins: [ValidationMixin, DateTimeMixin, OrdersMixin],
  props: {
    modelValue: Object,
    disabled: Boolean,
    error: String,
    multiline: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      showSecondDate: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    dateSpan() {
      return this.multiline ? 6 : 4;
    },
  },
  watch: {
    showSecondDate(val) {
      if (!val) {
        this.model.time2Type = null;
        this.model.time2From = null;
        this.model.time2To = null;
      }
    },
  },
  mounted() {
    if (this.model.time2Type && this.model.time2To) {
      this.showSecondDate = true;
    }
  },
});
</script>

<template>
  <v-row class="medium-padding">
    <v-col lg="6" sm="6" xl="6">
      <v-row class="medium-padding">
        <v-col sm="6" xl="8">
          <omni-select
            v-model="model.timezone"
            :disabled="disabled"
            :items="timezonesList"
            :rules="[requiredValidator]"
            data-qa="timezone"
            label="Time zone"
            required
          />
        </v-col>
        <v-col xl="4">
          <omni-select
            v-model="model.timeType"
            :disabled="disabled"
            :items="getTimeTypes(model.type)"
            :rules="[requiredValidator]"
            data-qa="time_type"
            label="Time type"
            required
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col :cols="dateSpan">
      <order-stop-date-time
        v-if="model.timeType === 'f'"
        v-model="model.timeFrom"
        :disabled="disabled"
        :timezone="model.timezone"
        date-label="Date from"
        date-qa-attr="date_from"
        time-label="Time from"
        time-qa-attr="time_from"
      ></order-stop-date-time>
      <order-stop-date-time
        v-model="model.timeTo"
        :disabled="disabled"
        :timezone="model.timezone"
        date-label="Date to"
        date-qa-attr="date_to"
        required
        time-label="Time to"
        time-qa-attr="time_to"
      ></order-stop-date-time>
    </v-col>
    <v-col v-if="!multiline" lg="2" sm="1" xl="2">
      <v-checkbox
        v-model="showSecondDate"
        :disabled="disabled"
        class="mb-2"
        color="primary"
        data-qa="show-second-date"
        density="compact"
        hide-details
        label="2nd time frame"
      />
    </v-col>
  </v-row>
  <v-row v-if="error" class="text-red-lighten-2">
    <v-col>{{ error }}</v-col>
  </v-row>
  <v-row v-if="multiline">
    <v-col>
      <v-checkbox
        v-model="showSecondDate"
        :disabled="disabled"
        color="primary"
        data-qa="show-second-date"
        density="compact"
        hide-details
        label="2nd time frame"
      />
    </v-col>
  </v-row>
  <template v-if="showSecondDate">
    <v-row class="medium-padding">
      <v-col lg="3" offset-lg="3" offset-sm="3" offset-xl="4" sm="3" xl="2">
        <omni-select
          v-model="model.time2Type"
          :disabled="disabled"
          :items="getTimeTypes(model.type)"
          :rules="[requiredValidator]"
          data-qa="time2_type"
          label="Time type"
          required
        />
      </v-col>
      <v-col :cols="dateSpan">
        <order-stop-date-time
          v-if="model.time2Type === 'f'"
          v-model="model.time2From"
          :disabled="disabled"
          :timezone="model.timezone"
          date-label="Date from"
          date-qa-attr="date2_from"
          time-label="Time from"
          time-qa-attr="time2_from"
        ></order-stop-date-time>
        <order-stop-date-time
          v-model="model.time2To"
          :disabled="disabled"
          :timezone="model.timezone"
          date-label="Date to"
          date-qa-attr="date2_to"
          required
          time-label="Time to"
          time-qa-attr="time2_to"
        ></order-stop-date-time>
      </v-col>
      <v-col></v-col>
    </v-row>
  </template>
</template>

<style lang="scss" scoped></style>
